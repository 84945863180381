@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.footer {
  padding: 56px 0;
  color: #fff;
  background: $color__darkblue-2;

  p {
    font-size: 14px;
    line-height: 1.7;

    @include bp(desktop-wide) {
      font-size: 16px;
    }
  }

  @include bp(tablet) {
    padding: 72px 0;
  }

  @include bp(desktop-large) {
    padding: 80px 0;
  }

  @include bp(desktop-wide) {
    padding: 96px 0;
  }
}

.footer__logo {
  width: 171px;
  height: auto;

  @include bp(desktop-large) {
    width: 205px;
  }

  @include bp(desktop-wide) {
    width: 240px;
  }
}

.footer__backlink {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;

  img {
    margin-right: 24px;
  }
}

.footer__links {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    width: 100%;
    margin: 40px 0 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 32px;
    column-gap: 109px;

    @include bp(tablet) {
      display: flex;
      justify-content: space-between;
    }

    @include bp(desktop-large) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 10px 0;
      row-gap: 24px;
    }

    @include bp(desktop-wide) {
      row-gap: 18px;
    }
  }

  a {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
  }

  @include bp(desktop-large) {
    order: 2;
  }
}

.footer__backlinkCol {
  display: flex;
  justify-content: flex-end;

  @include bp(desktop-large) {
    justify-content: flex-start;
  }
}

.footer__text {
  order: 2;

  @include bp(desktop-large) {
    order: 1;
  }
}

.footer__openbtn {
  margin-bottom: 0;
}

.footer__copy {
  color: $color__gray-4;
  font-size: 12px;
  letter-spacing: 1px;
}

.footer__spacer {
  @include bp(desktop-large) {
    order: 2;
  }
}

.footer__bottom {
  padding-bottom: 48px;
  border-bottom: 1px solid rgba($color__gray-4, 0.3);

  @include bp(desktop-large) {
    margin-top: 32px;
  }

  @include bp(desktop-wide) {
    margin-top: 10px;
  }
}

.footer__copyCol {
  @include bp(desktop-large) {
    display: flex;
    align-items: center;
    margin-top: -58px;
  }

  &_tm {
    @include bp(desktop-wide) {
      margin-top: 24px;
    }
  }
}

ul.footer__awards {
  row-gap: 35px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include bp(tablet) {
    column-gap: 109px;
  }

  @include bp(desktop-wide) {
    column-gap: 104px;
  }
}

.footer__award {
  display: flex;
  flex-direction: column;
  align-items: center;

  [alt="financial adviser award"] {
    width: 94px;
  }

  strong {
    display: block;
    font-size: 14px;
    line-height: 1.14;
    color: #818c99;
    opacity: 0.8;
    text-align: center;
  }

  img {
    height: auto;
    object-fit: cover;

    @include bp(0 mobile-med) {
      max-width: 80px;
    }
  }

  [alt="ebm award"] {
    width: 95px;
  }

  [alt="gi award"] {
    width: 80px;
  }

  [alt="bb award"] {
    width: 102px;
  }
}

.footer__awardTop {
  width: 100%;
  height: 60px;
  padding: 0 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 31px;
    height: 60px;
    background: url("/images/palm-branch.svg");
  }

  &:after {
    right: 0;
    left: auto;
    transform: scaleX(-1);
  }
}

.footer__disclaimer {
  padding-top: 48px;

  a {
    color: $color__gray-4;
    text-decoration: underline;
  }

  p {
    color: $color__gray-4;
    font-size: 12px;
    line-height: 1.6;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: $color__gray-4;
    font-size: 12px;
    line-height: 1.6;
  }

  li {
    padding-left: 14px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      background-color: $color__gray-4;
      transform: rotate(45deg);
    }
  }

  li + li {
    margin-top: 6px;
  }
}
