@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.modal {
  position: fixed;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: -999; // TODO: remake

  // @include bp(desktop-large) {
  //     height: 100vh;
  //     overflow: hidden;
  // }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.8;
    z-index: -1;
  }

  .modal__wrap {
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
    height: 100%;
    width: 100%;
  }

  &.opened {
    opacity: 1;
    z-index: 999; // TODO: remake
    pointer-events: all;

    .modal__wrap {
      transform: translateX(0%);
    }
  }
}