@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.SignupForm {}

.SignupForm__form {
    display: flex;
    align-items: center;
    position: absolute;
    // top: -72px;

    // height: calc(100% + 72px);
    height: 100%;
    right: 0;
    z-index: 101;
    background-color: $color__green--light;
    padding: 80px 40px 40px;
    display: block;
    overflow-y: auto;

    @include bp(desktop-large) {
        width: 50%;
        display: block;
        // transform: translateX(2000px);
        // transition: transform 0.3s;
        padding: 144px 128px 176px 72px;
    }

    &.show {
        // display: block;

        // @include bp(desktop-large) {
        //     transform: translateX(0);
        // }
    }
}



.SignupForm__close {
    color: #ffffff;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: url("/images/close-icon.svg");
    width: 48px;
    height: 48px;
    cursor: pointer;

    @include bp(desktop-large) {
        // right: 60px;
        right: 120px;
        top: 100px;
    }
}

// .SignupForm__signup {
//     button {
//         color: $color__green--light;
//         background-color: #ffffff;
//     }
// }

.SignupForm__note {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
}